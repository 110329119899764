import { countries } from "../../shared";
import { Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { AutocompleteController } from "../form";
import { memo, useState } from "react";
import PropTypes from "prop-types";

export const CountriesAutocomplete = (
  {
    control,
    name = 'country',
    label = 'Country',
    onChange = () => {},
    required
  }) => {

  const [selectedCountry, setSelectedCountry] = useState('US');

  return (
    <AutocompleteController
      disabled
      name={name}
      label={label}
      control={control}
      options={countries}
      onChange={(data) => {
        setSelectedCountry(data?.code);
        onChange(data);
      }}
      disableClearable={true}
      identityField="code"
      optionFieldName="code"
      defaultValue={selectedCountry}
      getOptionLabel={(option) => option.name}
      required={required}
      renderOption={(props, option) => (
        <Box component="li" {...props} gap={'14px'}>
          <CountryFlag code={option.code}/>
          {option.name}
        </Box>
      )}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CountryFlag sx={{marginRight: '4px'}} code={selectedCountry}/>
          </InputAdornment>
        ),
      }}/>
  )
}

CountriesAutocomplete.propTypes = {
    control: PropTypes.any,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
}

const CountryFlag = memo(({code, sx}) => {
  const [showFlag, setShowFlag] = useState(!!code);

  return (
    showFlag && code && (
      <Box sx={sx}>
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
          onError={() => setShowFlag(false)}
          alt=""
        />
      </Box>
    )
  );
});

CountryFlag.propTypes = {
    code: PropTypes.string,
    sx: PropTypes.object,
}
