import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import _get from "lodash.get";
import PropTypes from "prop-types";

export const DatePickerController = (
  {
    control,
    defaultValue,
    name,
    label,
    inputFormat,
    minDate,
    sx,
    onChange = () => {
    },
    views,
    required,
    errorMessage
  }
) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(
        {
          field: {
            onChange: fieldOnChange, ...fieldProps
          },
          formState
        }) => (
        <DatePicker
          {...fieldProps}
          label={label}
          views={views || ['year', 'month', 'day']}
          inputFormat={inputFormat || 'MM/DD/YYYY'}
          minDate={minDate}
          desktopModeMediaQuery={'@media (min-width: 720px)'}
          value={fieldProps.value}
          onChange={(e) => {
            fieldOnChange(e);
            onChange(e)
          }}
          slotProps={{
            textField: {
              variant: "standard",
              required: required,
              error: !!errorMessage || !!_get(formState.errors, name)
            },
            inputAdornment: {
              sx: {
                marginLeft: 0,
                paddingRight: '2px'
              }
            }
          }}
          sx={sx}
        />
      )}
    />
  );
};

DatePickerController.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    control: PropTypes.any,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    inputFormat: PropTypes.string,
    minDate: PropTypes.any,
    sx: PropTypes.object,
    views: PropTypes.array,
    errorMessage: PropTypes.any,
}
