import { componentsOverrides } from "./common/componentsOverrides";
import { typography } from "./common/typography";

export const darkTheme = {
  components: componentsOverrides,
  palette: {
    type: 'dark',
    primary: {
      // main: '#b8deff',
      main: '#57728A',
      dark: '#274459',
      light: '#8AA7C0',
    },
    secondary: {
      main: '#C22130',
      light: '#F73378',
      dark: '#AB003C',
      contrastText: '#FFF',
    },
    background: {
      default: '#272727',
      paper: '#2C2E30',
      dark: '#181A1C',
      table: '#333333',
      error: 'rgba(179, 50, 54, 0.2)'
    },
    input: {
      main: '#BDBDBD'
    },
    gradient: {
      dark: 'rgba(7, 28, 46, 50%)',
      light: 'rgba(37, 66, 88, 50%)',
    },
    border: {
      paper: '#2C2E30',
      table: '#5C5C5C',
    },
    text: {
      primary: '#EDEDED',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)'
    },
    warning: {
      main: '#DAC934',
      dark: '#BBAC24',
      light: '#F3E77D',
    },
    error: {
      main: '#B33236',
      light: '#ED6B64',
      dark: '#781510',
    },
    info: {
      main: '#274459',
    },
  },
  typography
};
