import { Controller } from "react-hook-form";
import _get from "lodash.get";
import { TimePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";

export const TimePickerController = (
  {
    control,
    defaultValue,
    name,
    label,
    sx,
    required,
    errorMessage,
    onChange = () => {
    },
    desktopModeMediaQuery
  }
) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(
        {
          field: {onChange: fieldOnChange, ref, ...fieldProps},
          formState
        }) => (
        <TimePicker
          {...fieldProps}
          inputRef={ref}
          label={label}
          sx={sx}
          desktopModeMediaQuery={desktopModeMediaQuery || '@media (min-width: 720px)'}
          value={fieldProps.value}
          skipDisabled={true}
          thresholdToRenderTimeInASingleColumn={48}
          timeSteps={{minutes: 30}}
          onChange={(e) => {
            fieldOnChange(e);
            onChange(e);
          }}
          shouldDisableTime={(val) => {
            if (val.minute() === 59) {
              return true;
            }
            return false;
          }}
          slotProps={{
            textField: {
              variant: "standard",
              required: required,
              error: !!errorMessage || !!_get(formState.errors, name)
            },
            inputAdornment: {
              sx: {
                marginLeft: 0,
                paddingRight: '2px'
              }
            }
          }}
        />
      )}
    />
  );
};

TimePickerController.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    control: PropTypes.any,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    sx: PropTypes.object,
    errorMessage: PropTypes.any,
    desktopModeMediaQuery: PropTypes.string,
}

