import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import _get from 'lodash.get';
import PropTypes from "prop-types";

const selectStyle = {
  "& .MuiSvgIcon-root": {
    color: "#ededed",
  }
}

export function SelectController(
  {
    name,
    label,
    control,
    options,
    getOptionLabel = (option) => option,
    onChange = () => { },
    optionFieldName,
    identityField,
    defaultValue,
    disabled,
    required,
    onOpen = () => { },
    sx
  }) {

  return (
    <Box display="flex" flex={1}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field, formState }) => (
          <FormControl variant="standard" sx={sx} fullWidth error={!!_get(formState.errors, field.name)}>
            <InputLabel required={required} id="select-label">{label}</InputLabel>
            <Select labelId="select-label" {...field} disabled={disabled}
                    sx={selectStyle}
              onOpen={onOpen}
              onChange={(_, child) => {
                const data = child.props.data
                field.onChange(optionFieldName && data ? data[optionFieldName] : data);
                onChange(data);
              }}
              MenuProps={{
                style: {
                  maxHeight: 300,
                },
              }}>
              {
                options?.map((opt) => (
                  <MenuItem
                    data={opt} //used inside onChange
                    key={opt?.[identityField] || opt}
                    value={opt?.[optionFieldName] || opt}>
                    {getOptionLabel(opt)}
                  </MenuItem>
                ))
              }
            </Select>

            <FormHelperText error={!!_get(formState.errors, field.name)}>
              {_get(formState.errors, field.name)?.message}
            </FormHelperText>
          </FormControl>
        )}
      />
    </Box>
  );
}

SelectController.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    control: PropTypes.any,
    options: PropTypes.array,
    required: PropTypes.bool,
    getOptionLabel: PropTypes.func,
    onChange: PropTypes.func,
    onOpen: PropTypes.func,
    optionFieldName: PropTypes.string,
    identityField: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
}
