export const componentsOverrides = {
  MuiTextField: {
    styleOverrides: {
      root: {
        height: '70px'
      }
    }
  },

  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: '14px'
      }
    }
  },

  MuiInputBase: {
    styleOverrides: {
      root: ({theme}) => ({
        '&:before, &:after': {
          borderBottomColor: `${theme.palette.primary.main} !important`
        }
      })
    }
  },

  MuiIconButton: {
    styleOverrides: {
      root: ({theme}) => ({
        color: `${theme.palette.text.secondary} !important`,
        "&.Mui-disabled": {
          opacity: 0.4
        },
      })
    }
  },

  MuiTypography: {
    styleOverrides: {
      caption: ({theme}) => ({
          color: `${theme.palette.text.primary}`
      })
    }
  },

  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: () => ({
        paddingRight: '40px !important'
      })
    }
  }
}
