import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import React from 'react';

import { Controller } from "react-hook-form";

import _get from 'lodash.get';
import { useTheme } from '@mui/material';
import PropTypes from "prop-types";

const PopperComponent = (props) => {
  return (
    <Popper {...props}
            style={{
              width: 'fit-content',
              maxWidth: '350px',
              boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.4)',
            }}
            placement='bottom-start'/>
  );
};

export function AutocompleteController(
  {
    name,
    label,
    control,
    options,
    required,
    getOptionLabel = (option) => option,
    onChange = () => {
    },
    onOpen = () => {
    },
    renderOption,
    optionFieldName,
    disableCloseOnSelect,
    listboxProps = {maxHeight: '15rem'},
    loading,
    multiple,
    limitTags,
    identityField,
    defaultValue,
    disableClearable,
    disabled,
    sx,
    InputProps = {}
  }) {
  const theme = useTheme();

  return (
    <Box display="flex" flex={1}>
      <Controller
        name={name}
        control={control}
        render={({field, formState}) => (
          <Autocomplete
            fullWidth
            disablePortal
            disableClearable={disableClearable}
            ListboxProps={{style: listboxProps}}
            options={options}
            onChange={(_, data) => {
              field.onChange(optionFieldName && data ? data[optionFieldName] : data);
              onChange(data);
            }}
            onOpen={() => onOpen()}
            value={(optionFieldName ? options?.find((o) => o[optionFieldName] === field.value) : field.value) || null}
            loading={loading}
            disableCloseOnSelect={disableCloseOnSelect}
            multiple={multiple}
            limitTags={limitTags}
            renderOption={renderOption}
            isOptionEqualToValue={(option, value) => identityField ? option[identityField] === value[identityField] : option === value}
            getOptionLabel={getOptionLabel}
            defaultValue={defaultValue}
            disabled={disabled}
            sx={sx}
            PopperComponent={PopperComponent}
            renderInput={(params) => (
              <form autoComplete="off">
                <TextField
                  variant="standard"
                  required={required}
                  fullWidth
                  sx={{height: '100%', svg: { color: theme.palette.input.main }, minHeight: '70px'}}
                  label={label}
                  error={!!_get(formState.errors, field.name)}
                  helperText={_get(formState.errors, field.name)?.message}
                  {...params}
                  InputProps={{
                    autoComplete: 'none',
                    inputProps: {
                      ...params.inputProps
                    },
                    ...params.InputProps,
                    ...InputProps
                  }}/>
              </form>
            )}/>
        )}/>
    </Box>
  );
}

AutocompleteController.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    control: PropTypes.any,
    options: PropTypes.array,
    required: PropTypes.bool,
    getOptionLabel: PropTypes.func,
    onChange: PropTypes.func,
    onOpen: PropTypes.func,
    renderOption: PropTypes.any,
    optionFieldName: PropTypes.string,
    disableCloseOnSelect: PropTypes.bool,
    listboxProps: PropTypes.object,
    loading: PropTypes.bool,
    multiple: PropTypes.bool,
    limitTags: PropTypes.number,
    identityField: PropTypes.string,
    defaultValue: PropTypes.any,
    disableClearable: PropTypes.bool,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    InputProps: PropTypes.any,
}
