import { useEffect } from 'react';
import { API } from "aws-amplify";
import { createCache, useCache } from "@react-hook/cache";
import _sortBy from 'lodash.sortby';

const dictionaryItemTypes = {
  freightTypes: 'PIECETYPE',
  accessorials: 'ACCESSORIAL',
  serviceLevels: 'SERVICELVL',
  refTypes: 'REFTYPE'
}

function createDictionaryCache() {
  return createCache(async (key) => {
    const apiName = 'SHIPMENTS_API';
    const path = `dictionary?type=${dictionaryItemTypes[key]}`;

    const data = await API.get(apiName, path);
    return _sortBy(data?.map(({key, value}) => ({ code: key, name: value })), 'name');
  }, 100);
}

let fetchCache = createDictionaryCache();

function useDictionary(dictionaryItemType) {
  const [{status, value}, fetchData] = useCache(
    fetchCache,
    dictionaryItemType
  )

  useEffect(() => {
    if (status === 'idle') {
      fetchData()
    }
  }, [fetchData, status])

  return value;
}

function useClearDictionary () {
  const clear = () => {
    fetchCache = createDictionaryCache();
  }

  return clear;
}

export default useDictionary;
export {useClearDictionary};
