import { lazy } from 'react';

import MainLayout from "../layout/MainLayout";
import { Loadable } from "../components";

const CustomersList = Loadable(lazy(() => import('../pages/Customers/CustomersPage')));

const AdminRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'admin',
      children: [
        {
          path: 'customers',
          element: <CustomersList/>,
        }
      ]
    }
  ]
};

export default AdminRoutes;
