import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { AppToolbar } from './AppToolbar/AppToolbar';
import { AuthRoute } from '../../context/AuthContext';

const MainLayout = () => {
  const pageWrapperSx = {
    display: 'flex',
    flex: 1,
    width: '100%',
    maxWidth: '1440px',
    alignSelf: 'center',
    maxHeight: 'calc(100vh - 64px)',
    minHeight: 'calc(100vh - 64px)'
  };
  
  return (
    <AuthRoute>
      <Box component="main" sx={{ display: 'flex', width: '100%', flexDirection: 'column'}}>
        <AppToolbar/>
        <Box component="div" sx={pageWrapperSx}>
          <Outlet/>
        </Box>
      </Box>
    </AuthRoute>
  );
};

export default MainLayout;
