import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Amplify, Auth} from "aws-amplify";
import dayjs from "dayjs";

// dayjs plugins extending section
import isBetween from 'dayjs/plugin/isBetween';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import toObject from 'dayjs/plugin/toObject';
import weekday from 'dayjs/plugin/weekday';
import objectSupport from 'dayjs/plugin/objectSupport';

dayjs.extend(isBetween);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(toObject);
dayjs.extend(weekday);
dayjs.extend(objectSupport);
// end

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    region: process.env.REACT_APP_REGION,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin'
      ]
    }
  },
  API: {
    endpoints: [
      {
        name: 'ADDRESSES_API',
        endpoint: process.env.REACT_APP_ADDRESSES_API_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
      {
        name: 'AUTH_API',
        endpoint: process.env.REACT_APP_AUTH_API_URL,
        custom_header: async (route) => {
          console.log(route);
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
      {
        name: 'CUSTOMERS_API',
        endpoint: process.env.REACT_APP_CUSTOMERS_API_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
      {
        name: 'COMMODITIES_API',
        endpoint: process.env.REACT_APP_COMMODITIES_API_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
      {
        name: 'REPORTS_API',
        endpoint: process.env.REACT_APP_REPORTS_API_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
      {
        name: 'SHIPMENTS_API',
        endpoint: process.env.REACT_APP_SHIPMENTS_API_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
