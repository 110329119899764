import { memo, useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { API } from "aws-amplify";
import { Box, LinearProgress, Stack } from "@mui/material";
import './PowerBiReport.scss'
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

const EMBED_SETTINGS_CHECK_INTERVAL_MS = 60 * 1000;

const visualSettings = {
  bars: {
    statusBar: {
      visible: false
    }
  },
  panes: {
    filters: {
      visible: false
    },
    pageNavigation: {
      visible: false
    },
  },
  background: models.BackgroundType.Transparent
}

function getStorageKey(reportType) {
  const EMBED_SETTINGS_STORAGE_KEY_SUFFIX = 'report-embed-config'
  return reportType + '_' + EMBED_SETTINGS_STORAGE_KEY_SUFFIX;
}

function getSettingsFromStorage(reportType) {
  let settings;
  try {
    settings = JSON.parse(sessionStorage.getItem(getStorageKey(reportType)));
  } catch (err) {
    console.error(err);
    return null;
  }

  if (settings && (new Date().getTime() <= new Date(settings?.expiry || null).getTime())) {
    return settings;
  }

  return null;
}

function saveSettingsToStorage(reportType, payload) {
  sessionStorage.setItem(getStorageKey(reportType), JSON.stringify(payload))
}

export const PowerBiReport = memo(({reportType, onButtonClick}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [embedSettings, setEmbedSettings] = useState(getSettingsFromStorage(reportType));
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!embedSettings) {
      fetchEmbedSettings(reportType).catch(err => console.error(err));
    }
  }, []);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (!getSettingsFromStorage(reportType)) {
        fetchEmbedSettings(reportType).catch(err => console.error(err));
      }
    }, EMBED_SETTINGS_CHECK_INTERVAL_MS);
    return () => clearInterval(myInterval); // clear the interval when the component unmounts
  }, []);

  const fetchEmbedSettings = async (reportTypePath) => {
    const apiName = 'REPORTS_API';
    const path = `reports/${reportTypePath}`;

    const result = await API.get(apiName, path, null)
      .catch(err => {
        enqueueSnackbar('Couldn\'t fetch report settings', {variant: 'error'});
        console.log('ERROR', err);
        return null;
      });

    saveSettingsToStorage(reportType, result);
    setEmbedSettings(result);
  }

  const loaderSx = {
    display: 'flex',
    background: (theme) => theme.palette.background.default,
    height: '100%',
    width: '100%',
    position: 'absolute'
  }

  return (
    <Box sx={{display: 'flex', flex: "1 0 240px", position: 'relative', marginRight: '16px'}}>
      {!loaded && <Box id="loading-cover" sx={loaderSx}>
        <Stack sx={{width: '100%', justifyContent: 'center'}} spacing={2}>
          <LinearProgress color="info"/>
        </Stack>
      </Box>}

      {!!embedSettings &&
        <PowerBIEmbed
          embedConfig={{
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
            id: embedSettings.reportId, // reportId
            embedUrl: embedSettings.embedUrl,
            accessToken: embedSettings.accessToken,
            tokenType: models.TokenType.Embed,
            settings: visualSettings
          }}
          eventHandlers={
            new Map([
              ['loaded', () => {
                console.log('Report loaded');
              }],
              ['rendered', () => {
                setLoaded(true);
                console.log('Report rendered');
              }],
              ['error', (event) => {
                console.log(event.detail);
              }],
              ['buttonClicked', (event, report) => {
                onButtonClick && onButtonClick(event?.detail, report);
              }]
            ])
          }
          cssClassName="report-container"
        />}

    </Box>
  )
});

PowerBiReport.propTypes = {
  reportType: PropTypes.string,
  onButtonClick: PropTypes.func,
}
