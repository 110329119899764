import { Controller } from "react-hook-form";
import { Box, TextField } from "@mui/material";
import _get from 'lodash.get';
import PropTypes from "prop-types";

const preventInputFirstZero = (e) => {
  if (e.target.value.length === 1 && e.target.value === "0" ) {
    return e.target.value = '';
  }
}

const parseInputValueAsNumber = (e) => {
  if (!e.target.value) {
    return '';
  }

  const parsedValue = parseInt(e.target.value, 10);
  if (isNaN(parsedValue)) {
    return;
  }

  return parsedValue;
};

export function TextFieldController(
  {
    name,
    label,
    control,
    required,
    defaultValue = '',
    inputProps,
    onlyNumbers,
    skipFirstZero,
    errorMessage,
    sx,
    onChange = () => {
    },
    onBlur = () => {
    },
    disabled = false,
  }) {

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
                   field: {onChange: fieldOnChange, onBlur: fieldOnBlur, ...field},
                   formState
                 }) => (
          <form autoComplete="off">
            <TextField
              variant="standard"
              fullWidth
              label={label}
              required={required}
              disabled={disabled}
              error={!!errorMessage ? !!errorMessage : !!_get(formState.errors, field.name)}
              helperText={errorMessage ? errorMessage : _get(formState.errors, field.name)?.message}
              sx={{...sx}}
              inputProps={{...inputProps}}
              onChange={(e) => {
                let value;
                if (skipFirstZero) {
                  preventInputFirstZero(e);
                }
                if (onlyNumbers) {
                  value = parseInputValueAsNumber(e);
                  if (value === undefined) return;
                } else {
                  value = e.target.value;
                }

                fieldOnChange(value);
                onChange(value);
              }}
              onBlur={(e) => {
                const value = e.target.value;
                fieldOnBlur(value);
                onBlur(value);

                !onlyNumbers && fieldOnChange(value.trim());
              }}
              {...field}/>
          </form>
        )}/>
    </Box>
  );
}

TextFieldController.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    control: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    defaultValue: PropTypes.any,
    inputProps: PropTypes.any,
    onlyNumbers: PropTypes.bool,
    skipFirstZero: PropTypes.bool,
    sx: PropTypes.object,
    errorMessage: PropTypes.any,
}
