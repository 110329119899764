import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useClearDictionary } from "../hooks/useDictionary";

const adminGroupName = process.env.REACT_APP_ADMIN_USER_GROUP;
const AuthContext = createContext();

const isUserAdmin = (user) => {
  const userGroups = user?.getSignInUserSession()?.getAccessToken().decodePayload()['cognito:groups'] || [];
  return userGroups?.includes(adminGroupName);
};

const AuthProvider = props => {
  const clearDictionaryFn = useClearDictionary();
  const [contextState, setContextState] = useState({
    user: undefined,
    permissions: [],
    isAdmin: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const handleDefaultRoute = (isAdmin) => {
    if (!isAdmin && location.pathname === '/') {
      navigate('/shipments');
    }

    if (isAdmin && location.pathname !== '/admin/customers') {
      navigate('/admin/customers');
    }
  };

  const proceedUserInfo = (user) => {
    const isAdmin = isUserAdmin(user);
    const permissions = user?.attributes['custom:permissions'].split(',');
    setContextState({ user, permissions, isAdmin });
    handleDefaultRoute(isAdmin);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(proceedUserInfo)
      .catch(() => setContextState({ user: null, permissions: [], isAdmin: false }));
  }, []);

  const { children } = props;

  const onLogout = () => {
    Auth.signOut()
      .finally(() => {
        setContextState({ user: null, permissions: [], isAdmin: false });
        sessionStorage.clear();
        clearDictionaryFn();
        navigate('/auth/login');
      });
  };

  const login = (usernameOrEmail, password) =>
    Auth.signIn(usernameOrEmail, password)
      .then(cognitoUser => {
        // TODO remove. Temp workaround to omit Change Pass flow
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(cognitoUser, password).then(proceedUserInfo);
        } else {
          proceedUserInfo(cognitoUser);
        }
        // return cognitoUser;
      })
      .catch((err) => {
        if (err.code === 'UserNotFoundException') {
          err.message = 'Incorrect username or password';
        }
        throw err;
      });

  return (
    <AuthContext.Provider
      value={{
        user: contextState.user,
        permissions: contextState.permissions,
        isAdmin: contextState.isAdmin,
        login,
        onLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}


const AuthRoute = ({ children }) => {
  const auth = useContext(AuthContext);

  if (auth.user === null) {
    return <Navigate to="/auth/login" />;
  }

  return children;
}

export { AuthContext, AuthProvider, AuthRoute };
