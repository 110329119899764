import { componentsOverrides } from "./common/componentsOverrides";
import { typography } from "./common/typography";

export const lightTheme = {
  components: componentsOverrides,
  palette: {
    type: 'light',
    primary: {
      main: '#57728A',
      dark: '#274459',
      light: '#8AA7C0',
    },
    secondary: {
      main: '#C22130',
      light: '#F73378',
      dark: '#AB003C',
      contrastText: '#FFF',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
      dark: '#d3d2d2',
      table: '#FFFFFF',
      error: 'rgba(179, 50, 54, 0.2)'
    },
    input: {
      main: '#57728A'
    },
    gradient: {
      dark: 'rgba(87, 114, 138, 30%)',
      light: 'rgba(87, 114, 138, 20%)',
    },
    border: {
      paper: '#E0E3E7',
      table: '#b5b5b5',
    },
    text: {
      primary: '#1A2027',
      secondary: '#0000008a',
      disabled: '#1c232a',
      hint: 'rgba(255, 255, 255, 0.5)',
    },
    warning: {
      main: '#DAC934',
      dark: '#BBAC24',
      light: '#F3E77D',
    },
    error: {
      main: '#B33236',
      light: '#ED6B64',
      dark: '#781510',
    },
    info: {
      main: '#274459',
    },
  },
  typography
};
