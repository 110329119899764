export const typography = {
  fontSize: 12,
  fontWeightLight: 300,
  h1: {
    fontSize: 20,
    fontWeight: 400,
  },
  h2: {
    fontSize: 16,
    fontWeight: 700,
  },
  h3: {
    fontSize: 14,
  },
  button: {
    fontSize: 14,
  },
  body2: {
    fontSize: 14,
  },
  body1: {
    fontSize: 16,
  },
  subtitle2: {
    fontSize: 12,
  },
  caption: {
    fontSize: 11,
  },
  overline: {
    fontSize: 11,
    fontWeight: 600,
  },
  h5: {
    fontSize: 14,
    fontWeight: 600,
  },
}
