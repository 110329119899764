import { lazy } from 'react';

import MainLayout from "../layout/MainLayout";
import { Loadable } from "../components";

const ActiveShipmentsPage = Loadable(lazy(() => import('../pages/ActiveShipmentsPage/ActiveShipmentsPage')));
const ProjectsSummary = Loadable(lazy(() => import('../pages/ProjectsSummary/ProjectsSummary')));
const QuotePage = Loadable(lazy(() => import('../pages/Quote/QuotePage')));
const ShipmentsHistory = Loadable(lazy(() => import('../pages/ShipmentsHistory/ShipmentsHistory')));
const ReportBuilder = Loadable(lazy(() => import('../pages/ReportBuilder/ReportBuilder')));
const NamesAndAddresses = Loadable(lazy(() => import('../pages/NamesAndAddresses/NamesAndAddresses')));
const Commodities = Loadable(lazy(() => import('../pages/Commodities/Commodities')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'shipments',
      permissions: ['shipments:view', 'shipments:create'],
      element: <ActiveShipmentsPage />
    },
    {
      path: 'projects',
      permissions: ['projects:view'],
      element: <ProjectsSummary />
    },
    {
      path: 'quote',
      permissions: ['quote:request'],
      element: <QuotePage />
    },
    {
      path: 'history',
      permissions: ['shipments:history'],
      element: <ShipmentsHistory/>
    },
    {
      path: 'report-builder',
      permissions: ['report-builder:view'],
      element: <ReportBuilder/>
    },
    {
      path: 'shippers-and-consignees',
      permissions: ['addresses:*'],
      element: <NamesAndAddresses/>
    },
    {
      path: 'commodities',
      permissions: ['shipments:view', 'shipments:create'],
      element: <Commodities/>
    },
    {
      path: '*'
    }
  ]
};

export default MainRoutes;
