import { Controller } from "react-hook-form";
import { Box, Checkbox } from "@mui/material";
import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";

export function CheckBoxController(
  {
    name,
    label,
    control,
    sx,
  }) {

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({field: props}) => (
          <form style={{display: 'flex', alignItems: 'center'}}>
            <Checkbox
              sx={{...sx}}
              {...props}
              checked={props.value}
              onChange={(e) => props.onChange(e.target.checked)}/>
            <Typography style={{marginLeft: '3px'}}>{label}</Typography>
          </form>
        )}/>
    </Box>
  );
}

CheckBoxController.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    control: PropTypes.any,
    sx: PropTypes.object,
}
