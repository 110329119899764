import { lazy } from 'react';

import { Loadable } from "../components";
import AuthLayout from "../layout/AuthLayout";

const Login = Loadable(lazy(() => import('../pages/Login/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/ForgotPassword/ForgotPassword')));
const ForgotPasswordConfirm = Loadable(lazy(() => import('../pages/ForgotPassword/ForgotPasswordConfirm')));

const LoginRoutes = {
  path: '/auth',
  element: <AuthLayout />,
  children: [
    {
      path: 'login',
      element: <Login />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: 'forgot-password/confirm',
      element: <ForgotPasswordConfirm />
    }
  ]
};

export default LoginRoutes;
