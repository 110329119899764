import { useContext } from 'react';
import PropTypes from 'prop-types';

import omniLogoLight from './omni_logo_light.png';
import omniLogo from './omni_logo.png';
import { ThemeContext } from '../../context/ThemeContext';

export default function OmniLogo ({size}) {
  const { isDarkTheme } = useContext(ThemeContext);

  return <img width={size}
              height={size}
              alt=""
              src={isDarkTheme ? omniLogo : omniLogoLight}/>
}

OmniLogo.propTypes = {
  size: PropTypes.number,
}
