import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

const AuthLayout = () => {
  return (
    <Box component="main" sx={{ display: 'flex', width: '100%', flexDirection: 'column'}}>
      <Outlet/>
    </Box>
  );
};

export default AuthLayout;
