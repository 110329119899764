import './App.scss';

import { createTheme, CssBaseline, IconButton, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { darkTheme } from './themes/dark';
import { lightTheme } from './themes/light';
import { AuthProvider } from './context/AuthContext';
import { ThemeContext } from './context/ThemeContext';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { hotjar } from "react-hotjar";


function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const notistackRef = useRef();

  // Hotjar
  useEffect(() => {
    const hotjarKeyStr = process.env.REACT_APP_HOTJAR_KEY;

    if (!hotjarKeyStr || !(/^\d+v\d{1,2}$/).test(hotjarKeyStr)) {
      console.log('Initialization of Hotjar is skipped. Key is invalid or missed.');
      return;
    }

    try {
      const [key, version] = hotjarKeyStr.split('v');
      hotjar.initialize(parseInt(key), parseInt(version));
    } catch (e) {
      console.error('Hotjar initialization failed.', e);
    }
  }, [])

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const iconStyles = {
    paddingRight: '5px',
    paddingTop: '5px'
  };

  const changeTheme = useCallback(() => {
    setIsDarkTheme(!isDarkTheme)
  }, [isDarkTheme]);

  const theme = useMemo(() => {
    return {
      isDarkTheme,
      changeTheme,
    };
  }, [isDarkTheme, changeTheme]);

  const snackbarAction = useCallback((key) => {
    return (
      <Box width="2rem">
        <IconButton onClick={onClickDismiss(key)} size="small">
          <CloseIcon fontSize="small" sx={{color: '#fff'}} />
        </IconButton>
      </Box>
    );
  }, []);

  return (
    <ThemeProvider theme={isDarkTheme ? createTheme(darkTheme) : createTheme(lightTheme)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <AuthProvider>
          <ThemeContext.Provider value={theme}>
            <SnackbarProvider maxSnack={3}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              ref={notistackRef}
              autoHideDuration={3000}
              action={snackbarAction}
              iconVariant={{
                success: <span style={iconStyles}><CheckCircleOutlineOutlinedIcon /></span>,
                error: <span style={iconStyles}><ErrorOutlineOutlinedIcon /></span>,
                info: <span style={iconStyles}><InfoOutlinedIcon /></span>,
              }}>
              <Routes />
            </SnackbarProvider>
          </ThemeContext.Provider>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
