import './AppToolbar.scss';
import OmniLogo from '../../../components/OmniLogo/OmniLogo';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DarkMode, LightMode, Logout, Mail, Person } from '@mui/icons-material';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Button from '@mui/material/Button';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { memo, useCallback, useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { AuthContext } from '../../../context/AuthContext';
import { ThemeContext } from '../../../context/ThemeContext';

export function AppToolbar() {
  const theme = useTheme();

  return (
    <AppBar position={'sticky'}
      sx={{ backgroundColor: theme.palette.background.default, maxWidth: '1468px', alignSelf: 'center' }}>
      <Toolbar sx={{ justifyContent: 'space-between', gap: '40px' }}>
        <Box display={'flex'} alignItems={'center'}>
          <OmniLogo size={30} />
        </Box>
        <Navigation></Navigation>
        <AccountMenu></AccountMenu>
      </Toolbar>
    </AppBar>
  );
}

const ThemeToggle = memo(() => {
  const { isDarkTheme, changeTheme } = useContext(ThemeContext);
  const [checked, setChecked] = useState(isDarkTheme);

  const handleChange = useCallback((event) => {
    setChecked(event.target.checked);
    changeTheme();
  }, []);

  return (
    <Box display={'flex'} alignItems={'center'}>
      <LightMode color="primary"></LightMode>
      <Switch color="primary"
        inputProps={{ 'aria-label': 'Switch theme' }}
        checked={checked}
        onChange={handleChange} />
      <DarkMode color="primary"></DarkMode>
    </Box>
  );
});

const AccountMenu = () => {
  const auth = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onLogout = useCallback(() => {
    auth.onLogout();
  }, [auth]);

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton onClick={handleClick} size="small">
          <Avatar
            sx={{ width: 32, height: 32, lineHeight: 20, bgcolor: '#57728A', color: 'white' }}>
            {auth.user?.attributes?.name.slice(0, 1)?.toUpperCase()}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <ThemeToggle></ThemeToggle>
        </MenuItem>

        <Divider />

        <MenuItem>
          <ListItemIcon>
            <Person fontSize="small" color={'primary'} />
          </ListItemIcon>
          {auth.user?.attributes?.name} {auth.user?.attributes?.family_name}
        </MenuItem>

        <MenuItem>
          <ListItemIcon>
            <Mail fontSize="small" color={'primary'} />
          </ListItemIcon>
          {auth.user?.username}
        </MenuItem>

        <Divider />

        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <Logout fontSize="small" color={'primary'} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}

const Navigation = memo(() => {
  const { isAdmin, permissions } = useContext(AuthContext);
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const popupState = usePopupState({ popupId: 'navigation-menu', variant: 'popover' });

  let navs = [
    {
      path: '/shipments',
      label: 'Active Shipments',
      permissions: ['shipments:view', 'shipments:create']
    },
    {
      path: '/projects',
      permissions: ['projects:view'],
      label: 'Project Summary'
    },
    {
      path: '/quote',
      label: 'Quick Quote',
      permissions: ['quote:request']
    },
    {
      path: '/history',
      label: 'History',
      permissions: ['shipments:history']
    },
    {
      path: '/report-builder',
      label: 'Report Builder',
      permissions: ['report-builder:view']
    },
    {
      path: '/shippers-and-consignees',
      label: 'Shippers & Consignees',
      permissions: ['addresses:*']
    },
    {
      path: '/commodities',
      label: 'Commodities',
      permissions: ['shipments:view', 'shipments:create']
    },
    {
      path: '/admin/customers',
      label: 'Customers',
      permissions: ['admin:*'],
      adminRoute: true,
    }
  ];

  navs = navs.filter(item => item.permissions?.some(i => permissions?.includes(i)));

  const navLinksFilter = (nav) => (isAdmin && nav.adminRoute) || (!isAdmin && !nav.adminRoute);

  const activeLabel = navs.find((nav) => nav.path === location.pathname)?.label;

  const linkStyles = useCallback(({ isActive }) => {
    const activeLinkStyle = theme.palette.type === 'dark'
      ? {
        color: theme.palette.primary.light,
        borderBottom: `4px solid ${theme.palette.primary.light}`,
      }
      : {
        color: theme.palette.primary.main,
        borderBottom: `4px solid ${theme.palette.primary.main}`,
      };

    const inactiveLinkStyle = {
      color: theme.palette.text.primary,
    };

    return isActive ? activeLinkStyle : inactiveLinkStyle;
  }, [theme]);

  const desktopVew = <Box display={'flex'} gap={'22px'} flex={1}>
    {navs.filter(navLinksFilter).map(({ label, path }) =>
      <NavLink key={label} to={path} className="nav-link"
        style={linkStyles}>
        {label}
      </NavLink>
    )}
  </Box>

  const mobileView = <div>
    <Button size="middle" {...bindTrigger(popupState)}>
      {activeLabel}
    </Button>
    <Menu {...bindMenu(popupState)}>
      {navs.filter(navLinksFilter).map(({ label, path }) =>
        <MenuItem component={NavLink} to={path} key={label} onClick={popupState.close}>
          {label}
        </MenuItem>
      )}
    </Menu>
  </div>

  return (
    isMobile ? mobileView : desktopVew
  )
})

