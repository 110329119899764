import { useRoutes, Navigate } from 'react-router-dom';
import { useContext } from 'react';

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AdminRoutes from './AdminRoutes';
import { AuthContext } from '../context/AuthContext';

export default function Routes() {
  const { isAdmin, user } = useContext(AuthContext);
  const routes = [LoginRoutes];

  // TODO refactor
  if (user) {
    if (isAdmin) {
      routes.push(AdminRoutes);
    } else {
      const userPermissions = user?.attributes['custom:permissions'].split(',')
      if (userPermissions) {
        MainRoutes.children = MainRoutes.children.filter(item => !item.permissions?.length || item.permissions?.some(i => userPermissions?.includes(i) ));
        MainRoutes.children[MainRoutes.children.length - 1].element = <Navigate to={MainRoutes.children[0].path}/>
      }
      routes.push(MainRoutes);
    }
  } else {
    routes.push({
      path: '*',
      element: <Navigate to="/auth/login" />
    });
  }

  return useRoutes(user !== undefined ? routes : []);
}
